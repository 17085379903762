import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter} from 'react-router-dom'
import App from './App'




// ========================================

ReactDOM.render(
	<BrowserRouter>
  		<App />
  	</BrowserRouter>,
  document.getElementById('root')
);
